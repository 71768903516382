import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import Account from './Account';
import Contacts from './Contacts';
import Notifications from './Notifications';
import Search from './Search';
import Settings from './Settings';
import Chip from '@material-ui/core/Chip';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default
    } : {}
  },
  toolbar: {
    minHeight: 64
  }
}));

function TopBar({
  className,
  onMobileNavOpen,
  ...rest
}) {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [gasDetails, setGasDetails] = useState(null);



  // useEffect(() => {
  //   fetch(`https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=S58UZIX1KXFB3B2H7BH4EUP9QJ8PFNXVI4`)
  //     .then(res => res.json())
  //     .then(
  //       (result) => {
  //         console.log('Get gas details success');
  //         setIsLoaded(true);
  //         setGasDetails(result.result);
  //         console.log(result)
  //       },
  //       // Note: it's important to handle errors here 
  //       // instead of a catch() block so that we don't swallow
  //       // exceptions from actual bugs in components.
  //       (error) => {
  //         console.log('Get gas details error');
  //         setIsLoaded(true);
  //         setError(error);
  //       }

  //     )
  // }, [])

  // if (!gasDetails) {
  //   return null;
  // }
  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Hidden>
        {/* <Tooltip title={
          `Base Fee: ${gasDetails.SafeGasPrice}\n
          Priority Fee: ${gasDetails.FastGasPrice}
          `
        } placement="right">
          <Chip
            size="small"
            avatar={<LocalGasStationIcon style={{ background: 'transparent' }} />}
            label={gasDetails.ProposeGasPrice + ' ' + 'Gwei'}
            clickable
            color="secondary"
          />
        </Tooltip> */}
        <Box
          ml={2}
          flexGrow={1}
        />
        <Search />
        <Contacts />
        <Notifications />
        <Settings />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;

import { BASE_URL } from './constants/baseUrl';
import authService from "./services/authService";
const Web3 = require('web3');
const HDWalletProvider = require('@truffle/hdwallet-provider');




let selectedAccount;
let nftContract;
let isInitialized = false;
let accounts = [];
let provider;
export const init = async () => {
  console.log("INITIALIZING WEB3 CLIENT "+isInitialized);
  const mnemonic = 'august team shoot public soft law target possible bomb fury grief insect faith blossom law clip total race waste spirit vanish soap material list';
  //const mnemonic = 'phrase segment boring angry sell omit match lunch vessel repair pyramid luggage';
   provider = new HDWalletProvider({
    mnemonic: {
      phrase: mnemonic
    },
    //providerOrUrl: 'https://warp-nd-713-678-509.p2pify.com/8798a5253d10a31c9066cc395a44c71c'
    providerOrUrl: 'https://nd-333-508-042.p2pify.com/d99680ce93c4b71cf5e351f3adf09495'
  });
  console.log(provider);
  const web3 = new Web3(provider);
  web3.eth.transactionPollingTimeout = 0; // Disable transaction polling
  web3.eth.blockPollingInterval = null; // Optionally disable block polling
  accounts = await web3.eth.getAccounts();
  console.log(accounts)

  const erc = [
    {
      "inputs": [
        {
          "internalType": "string",
          "name": "name",
          "type": "string"
        },
        {
          "internalType": "string",
          "name": "details",
          "type": "string"
        }
      ],
      "name": "issueCertificate",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }
  ];
  nftContract = await new web3.eth.Contract(erc, accounts[0]);
  console.log(nftContract)

  isInitialized = true;
};

export const issueCert = async (gasPrice, values, stuName) => {
  console.log("issueCert called with values :" + JSON.stringify(values) + " and gasPrice :" + JSON.stringify(gasPrice) + " isInitialized :" + isInitialized);
  if (!isInitialized) {
    console.log("NOT initialized WEB3 CLIENT "+isInitialized);
    await init();
  }

  console.log("SENDING TO BLOCKCHAIN : "+isInitialized+ " values :" + JSON.stringify(values) + JSON.stringify(gasPrice));
  const result = await nftContract.methods
    .issueCertificate(values.certificateId, stuName)
    .send({
      from: accounts[0],
      gasPrice: gasPrice,
      gasLimit: 24000,
    })
    .on("transactionHash", async function (hash) {
      values.certificateTxn = hash;
      console.log("BLOCKCHAIN TNX CREATED :transactionHash :" + hash + " updating in db :" + JSON.stringify(values));
      try {
        await authService.executeServiceApi(`${BASE_URL}/api/certificate/${values._id}`, 'PATCH', values);
      } catch (error) {
        console.log("certificate update failed with blockchain status :" + error);
      }
    })
    .on("error", async function (error) {
      values.blockchainCompleted = false;
      console.log("ERROR :BLOCKCHAIN TNX failed :" + JSON.stringify(error) + " and updating status :" + JSON.stringify(values));
      try {
        await authService.executeServiceApi(`${BASE_URL}/api/certificate/${values.certificateId}`, 'PATCH', values);
      } catch (error) {
        console.log("certificate update failed with blockchain status :" + error);
      }
    })
  console.log(result);
  if (provider && typeof provider.engine.stop === 'function') {
    console.log("STOPPING POLLING");
    provider.engine.stop(); // Stops polling for the provider
  }
  return result;
}

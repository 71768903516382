import jwtDecode from 'jwt-decode';
import { method } from 'lodash';
import { BASE_URL } from 'src/constants/baseUrl';
import axios from 'src/utils/axios';

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  loginWithEmailAndPassword = (email, password) => new Promise((resolve, reject) => {
    axios.post('/api/account/login', { email, password })
      .then((response) => {
        if (response.data.user) {
          this.setSession(response.data.accessToken);
          
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })

  

  loginInWithToken = () => new Promise((resolve, reject) => {
    axios.get('/api/account/me')
      .then((response) => {
        if (response.data.user) {
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })

  logout = () => {
    this.setSession(null);
  }

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  }

  setAPIValidationToken = (apiToken) => {
    if (apiToken) {
      // localStorage.setItem('accessToken', accessToken);
      fetch.defaults.headers.common.Authorization = `Bearer ${apiToken}`;
    } else {
      // localStorage.removeItem('accessToken');
      delete fetch.defaults.headers.common.Authorization;
    }
  }

  getAccessToken = () => localStorage.getItem('accessToken');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  }

  isAuthenticated = () => !!this.getAccessToken();

  getApiToken = (email) => new Promise(async (resolve, reject) => {
    try{
      console.log("................................... "+email)
      const jwtToken = await this.invokeTokenService(email);
      console.log("generated token "+JSON.stringify(jwtToken));
      // localStorage.setItem('token', JSON.stringify(jwtToken));
      return resolve(jwtToken.accessToken);
    }catch(error){
      console.log("Error occur :"+error);
      reject(error);
    }
  });

  invokeTokenService = (email) => new Promise((resolve, reject) => {
    const tokenReqPayLoad = {
      email: email,
    }
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(tokenReqPayLoad)
    };
    console.log(requestOptions);
    console.log(`${BASE_URL}/api/token`)
    fetch(`${BASE_URL}/api/token`, requestOptions)
    .then(response => response.json())
    .then(data =>{
      console.log('token returned from token service :'+JSON.stringify(data));
      resolve(data);
    })
    .catch((err) => {
      console.log("Error occur while requesting jwt token :"+err);
      reject(err);
    });
  });

  getAccessTokenByRefreshToken = (refreshToken) => new Promise((resolve, reject) => {
    const tokenReqPayLoad = {
      refreshToken: refreshToken,
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(tokenReqPayLoad)
    };

    fetch(`${BASE_URL}/api/token/refresh`, requestOptions)
    .then(response => response.json())
    .then(data =>{
      console.log('token returned from token service :'+JSON.stringify(data));
      resolve(data);
    })
    .catch((err) => {
      console.log("Error occur while requesting jwt token :"+err);
      reject(err);
    });
  });

   performFetchCall = (url, httpCall) => {
    return fetch(url, httpCall).then(response => {
      console.log("API call executed: ", response);
      if (response.ok) {
        return response.json();
      } else {
        return response.json().then(err =>{
          const errorMessage = `${err.message} (status: ${response.status})`;
          console.log(errorMessage)
          if (response.status === 401) {
            throw { isAuthError: true, message: err.message, status: response.status };
          }
          throw { isAuthError: false, message: err.message, status: response.status };;
        })
      }
    }).catch(error => {
      console.error("Error in fetch operation: ", error);
      throw error; 
  });;
  };

 executeServiceApi = (url, httpMethod, body,isExternalApi) => {
    console.log("URL: ", url, " Method: ", httpMethod," body :",JSON.stringify(body));
    let httpCall;
    if(isExternalApi !=undefined && isExternalApi===true){
      httpCall = {method: httpMethod};
    }else{
      httpCall = {
        method: httpMethod,
        credentials: 'include',
      };
    }
    if (body !== undefined && body !== null) {
      httpCall.headers = {'Content-Type': 'application/json'};
      httpCall.body = JSON.stringify(body);
    }
    console.log(httpCall.body);
    return new Promise((resolve, reject) => {
      this.performFetchCall(url, httpCall)
      .then(data => {
        console.log("response from server: "+JSON.stringify(data));
        resolve(data)})
      .catch(error => {
        console.log("Error occur while executing api "+error.message);
        if (error.isAuthError) {
          console.log("401 detected, attempting to refresh token");
          return fetch(`${BASE_URL}/api/refresh`, {
            method: 'POST',
            credentials: 'include'
          })
          .then(refreshResponse => {
            if (!refreshResponse.ok) {
              throw new Error("Session expired. Unable to refresh the token.");
            }
            return this.performFetchCall(url, httpCall);
          })
          .then(data => resolve(data))
          .catch(refreshError => reject(`Error after attempting token refresh: ${refreshError.message}`));
        } else {
          reject(error);
        }
      });
    });
  };
//   executeServiceApi = (url,http_method,body) => {
//     console.log("url :"+url +" method "+http_method);
//     console.log("body "+JSON.stringify(body));
//     let http_call ={
//       method: http_method,
//         credentials: 'include' 
//     };
//     if (body !=undefined && body !== null) {
//       http_call.headers= {'Content-Type': 'application/json'}
//       http_call.body = JSON.stringify(body);
//     }
//     return new Promise((resolve, reject) => {
//       fetch(url, http_call)
//       .then(response => {
//         console.log("############ executeServiceApi ######## "+response.body);
//         if (response.status === 401) {
//           return fetch(`${BASE_URL}/api/refresh`, {
//             method: 'POST',
//             credentials: 'include'
//           })
//           .then(refreshResponse => {
//             if (!refreshResponse.ok)  {
//               throw new Error("Session expired. Unable to refresh the token.");
//             }
//             return fetch(url,http_call);
//           });
//         }
//         console.log(response);
//         if(response.ok){
//           return (response.json());
//         }
//         console.log("API respose not success . status code :"+response.status);
//         return reject(`API response is not OK. Error in executeServiceApi: ${response.status}`); // Reject the promise with an error message
//       })
//       .then(data => {
//         return resolve(data); 
//       })
//       .catch(error => {
//         console.log("Error occured : "+url + " | "+http_method+" | "+error);
//         return reject(`Error in executeServiceApi: ${error.message}`); 
//       });
//     });
//   };
 }

const authService = new AuthService();

export default authService;

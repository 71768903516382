/* eslint-disable import/prefer-default-export */
export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};
export const OPERATION_FAILED= "Opps !!! Error occured. Try again later";
export const OPERATION_CREATE_SUCCESS= "Successfully created";
export const OPERATION_UPDATE_SUCCESS= "Successfully updated";
export const OPERATION_DELETE_SUCCESS= "Successfully deleted";
export const LOADING_TEXT = "Loading...";